import $ from 'jquery';
import Mn from 'backbone.marionette';
import scope from '../scope';
import swal from '../libs/swal';

var Behavior = Mn.Behavior.extend({
    ui: {
        delete: 'a[data-delete]'
    },
    events: {
        'click @ui.delete': 'doDelete'
    },
    doDelete: function(e){
        e.preventDefault();

        var self = this;
        var target = $(e.currentTarget);
        var current = window.location.href;
        var url = target.attr('href');

        swal.fire({
            title: 'Voce tem certeza?',
            text: "Após confirmar a remoção deste anúncio você nao poderá reverter esta ação!",
            icon: 'warning',
            showCancelButton: true,
            focusConfirm: false,
            focusCancel: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
          }).then((result) => {
            if (result.isConfirmed) {
                self.setDelete(url, function(){
                    swal.fire(
                        'Removido!',
                        'Seu anúncio foi removido com sucesso.',
                        'success'
                    );

                    scope.app.navigate(current, false, false, true);
                });
            }
          });
    },
    setDelete: function(url, callback){
        var self = this;
        scope.app.layout.waiting(true);

        scope.login_callback = function(){
            self.setDelete(url, function(){
                callback && callback();
            });
        };

        $.ajax({
            url: url,
            method: 'GET',
            dataType: 'json',
            complete: function(resp){
                if(resp.responseJSON && resp.responseJSON.success){
                    scope.app.layout.waiting(false);
                    scope.login_callback = null;
                    callback && callback(resp);
                }
            }
        });
    }
});

export default Behavior;