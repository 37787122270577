import Component from '../../libs/component';
import swal from '../../libs/swal';
import scope from '../../scope';
import Delete from '../../behaviors/Delete';
import Visible from '../../behaviors/Visible';

scope.components['posts'] = Component.extend({
    name: 'posts',
    ui: {
        list: '.posts__list ',
        pending: '[data-pending]'
    },
    events: {
        'initialize.carousel @ui.list': 'initCarousel',
        'click @ui.pending': 'alertPending'
    },
    behaviors: {
        'Delete': Delete,
        'Visible': Visible
    },
    initCarousel: function(e, data){
        data.options.watchCSS = true;
    },
    alertPending: function(e){
        e.preventDefault();
        e.stopPropagation();
        swal.fire('Aguarde', 'Seu anúncio está em moderação.', "info");
    }
});

export default scope.components['posts'];

