import $ from 'jquery';
import Mn from 'backbone.marionette';
import scope from '../scope';
import swal from '../libs/swal';

var Behavior = Mn.Behavior.extend({
    ui: {
        visible: 'a[data-visible]'
    },
    events: {
        'click @ui.visible': 'doVisible'
    },
    doVisible: function(e){
        e.preventDefault();

        var self = this;
        var target = $(e.currentTarget);
        var current = window.location.href;
        var url = target.attr('href');
        var active = target.hasClass('active');

        swal.fire({
            title: 'Voce tem certeza?',
            text: active ? 'Que deseja desativar este anúncio?' : 'Que deseja ativar este anúncio?',
            icon: 'warning',
            showCancelButton: true,
            focusConfirm: false,
            focusCancel: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
          }).then((result) => {
            if (result.isConfirmed) {
                self.setVisible(url, function(){
                    swal.fire(
                        active ? 'Desativada!' : 'Ativada!',
                        active ? 'Seu anúncio foi desativado com sucesso.' : 'Seu anúncio foi ativado com sucesso.',
                        'success'
                    );

                    if(active)
                        target.removeClass('active');
                    else
                        target.addClass('active');

                    scope.app.navigate(current, false, false, true);
                });
            }
          });
    },
    setVisible: function(url, callback){
        var self = this;
        scope.app.layout.waiting(true);

        scope.login_callback = function(){
            self.setVisible(url, function(){
                callback && callback();
            });
        };

        $.ajax({
            url: url,
            method: 'GET',
            dataType: 'json',
            complete: function(resp){
                if(resp.responseJSON && resp.responseJSON.success){
                    scope.app.layout.waiting(false);
                    scope.login_callback = null;
                    callback && callback(resp);
                }
            }
        });
    }
});

export default Behavior;